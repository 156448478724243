import React from "react"

// Blocks
import HeroActions from "../layout/HeroActions"

// Images
import twokids from "../../assets/images/twokids.svg"

const Hero = () => {
  return (
    <article className="hero">
      <div className="[ flex wrapper ]">
        <div className="">
          <h1 className="[ weight-bold text-600 md:text-700 ] [ hero__header ] ">
            <span>
              Finding people to learn and play with shouldn&apos;t be hard.
            </span>
            <small className="[ weight-normal text-500 md:text-600 ] [ hero__subheader ]">
              Join a group of learners or make your own
            </small>
          </h1>
          <HeroActions />
        </div>
        <div className="imageBox">
          <img src={twokids} alt="Illustration of kids in a circle." />
        </div>
      </div>
    </article>
  )
}

export default Hero
