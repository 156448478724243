import React from "react"

// Images
import cdc from "../../assets/images/cdc.jpg"
import gt from "../../assets/images/gt.jpg"
import jh from "../../assets/images/jh.jpg"

const HealthGuidelines = () => {
  return (
    <article
      id="health-guidelines"
      className="[ text-center panel ] [ bg-light ]"
    >
      <div className="wrapper">
        <h2 className="[ weight-bold text-700 md:text-900 ]">
          Health Guidelines
        </h2>
        <p>
          PodPlease does not take responsibility or make recommendations for
          pods. Here are some resources to help guide your decisions.
        </p>
        <ul className="[ blurb-group ] [ auto-grid ]">
          <li className="[ blurb ]">
            <img
              src={cdc}
              alt="Centers for Disease Control and Prevention Logo"
              className="[ blurb__image ]"
            />
            <h3 className="[ blurb__title ]">
              CDC School Reopening Guidelines
            </h3>
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/index.html"
              className="[ blurb__cta ]"
            >
              Click here
            </a>
          </li>
          <li className="[ blurb ]">
            <img
              src={gt}
              alt="Georgia Tech Logo"
              className="[ blurb__image ]"
            />
            <h3 className="[ blurb__title ]">In Person Risk Assessment Map</h3>
            <a
              href="https://covid19risk.biosci.gatech.edu/"
              className="[ blurb__cta ]"
            >
              Click here
            </a>
          </li>
          <li className="[ blurb ]">
            <img
              src={jh}
              alt="John Hopkins University Logo"
              className="[ blurb__image ]"
            />
            <h3 className="[ blurb__title ]">
              John Hopkins Covid-19 Dashboard
            </h3>
            <a
              href="https://coronavirus.jhu.edu/map.html"
              className="[ blurb__cta ]"
            >
              Click here
            </a>
          </li>
        </ul>
      </div>
    </article>
  )
}

export default HealthGuidelines
