import React from "react"

// Blocks
import HeroActions from "../layout/HeroActions"

const Beliefs = () => {
  return (
    <article id="beliefs" className="[ text-center panel ] [ bg-light ]">
      <div className="wrapper">
        <h2 className="[ weight-bold text-700 md:text-900 ]">
          What we believe
        </h2>
        <p>Why we created the PodPlease service</p>

        <ul className="[ blurb-group square-grid ] [ auto-grid ]">
          <li className="[ blurb ]">
            <p className="[ blurb__body ]">
              Communities can work together to further their children&apos;s
              education and emotional goals.
            </p>
          </li>
          <li className="[ blurb ]">
            <p className="[ blurb__body ]">
              Every child deserves to learn in a safe environment and should be
              supported in pursuing their educational goals.
            </p>
          </li>
          <li className="[ blurb ]">
            <p className="[ blurb__body ]">
              Pods will help families create strong communities that will
              continue to support one another even after the threat of COVID19
              ends.
            </p>
          </li>
        </ul>
      </div>
      <HeroActions />
    </article>
  )
}

export default Beliefs
