import React from "react"

// Blocks
import HeroActions from "../layout/HeroActions"

// Images
import objects from "../../assets/images/objects.svg"

const Steps = () => {
  return (
    <article id="steps" className="[ text-center panel ]">
      <div className="wrapper">
        <img
          className="[ hero ]"
          src={objects}
          alt="Illustration of a book, pencil, schoolbag, and a vial holding hands"
        />
        <h2 className="[ weight-bold text-700 md:text-900 ]">
          How does PodPlease work?
        </h2>
        <p>PodPlease is a free match making-service for parents.</p>
        <ul className="[ blurb-group square-grid ] [ auto-grid ]">
          <li className="[ blurb ]">
            <p className="[ blurb__eyebrow ]">Step 1</p>
            <h3 className="[ blurb__title ]">Search the pod listings</h3>
            <p className="[ blurb__body ]">
              Browse for the things that matter most to your family.
            </p>
          </li>
          <li className="[ blurb ]">
            <p className="[ blurb__eyebrow ]">Step 2</p>
            <h3 className="[ blurb__title ]">Submit an application</h3>
            <p className="[ blurb__body ]">
              Tell your story and let parents know why you should be a part of
              their pod.
            </p>
          </li>
          <li className="[ blurb ]">
            <p className="[ blurb__eyebrow ]">Step 3</p>
            <h3 className="[ blurb__title ]">Wait for a response</h3>
            <p className="[ blurb__body ]">
              You&apos;ll receive an email or phone response from the pod
              parent.
            </p>
          </li>
        </ul>
        <HeroActions />
      </div>
    </article>
  )
}

export default Steps
