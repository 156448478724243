import React from "react"

// Blocks
import HeroActions from "../layout/HeroActions"

const Highlights = () => {
  return (
    <article id="highlights" className="[ text-center panel ] [ bg-light ]">
      <div className="wrapper">
        <h2 className="[ weight-bold text-700 md:text-900 ]">
          How can a pod help?
        </h2>
        <p>
          Learning pods can feel very confusing. Here are some different ways
          people are using learning pods.
        </p>

        <ul className="[ blurb-group ] [ auto-grid ] [ square-grid ]">
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Virtual learning</h3>
            <p className="[ blurb__body ]">
              Learning pods don&apos;t have to happen in- person. Parents and
              kids can both provide a virtual support network online.
            </p>
          </li>
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">In-person learning</h3>
            <p className="[ blurb__body ]">
              Sometimes we have difficult decisions to make. Find other families
              who will need in-person learning support.
            </p>
          </li>
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Distributed childcare</h3>
            <p className="[ blurb__body ]">
              Need a group who can rotate the kids and share the load? Pods can
              help neighbors provide childcare for eachother.
            </p>
          </li>
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Start homeschooling</h3>
            <p className="[ blurb__body ]">
              PodPlease can help you find other parents who want to homeschool
              their children as a parent team or with a tutor.
            </p>
          </li>
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Self-guided learning</h3>
            <p className="[ blurb__body ]">
              Is your child interested in directing and forming a study pod with
              peers? Build a pod with other self-guided learners who can work
              together.
            </p>
          </li>
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Help kids stay social</h3>
            <p className="[ blurb__body ]">
              Learning doesn&apos;t have to be all about books and math. Pods
              help kids play together safely in-person and online.
            </p>
          </li>
        </ul>
      </div>
      <HeroActions />
    </article>
  )
}

export default Highlights
