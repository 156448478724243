import React from "react"

// Images
import adults from "../../assets/images/adults.svg"

const CommunityGuidelines = () => {
  return (
    <article id="community-guidelines" className="[ text-center panel ] ">
      <div className="wrapper">
        <img
          className="[ hero ]"
          src={adults}
          alt="Illustration of Four masked adults"
        />
        <h2 className="[ weight-bold text-700 md:text-900 ]">
          Community Guidelines
        </h2>
        <p>
          You will quickly need learn how to agree, disagree, and solve problems
          together with a team. That isn&apos;t easy! Some of you will have
          experience, some of you wont. Use these principles to help you have
          constructive learning discussions and teach your children.
        </p>

        <ul className="[ blurb-group square-grid ] [ auto-grid ]">
          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Don&apos;t judge each other</h3>
            <p className="[ blurb__body ]">
              Meet each parent and child where they are at in their emotional,
              social, learning, and parenting journey. Remember, everyone is
              trying their best and you don&apos;t know all the factors that are
              guiding their behavior.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">
              Be kind, be tender, choose love
            </h3>
            <p className="[ blurb__body ]">
              Always assume the best before assuming the worst. There is enough
              stress in our lives today and we can help calm that stress when we
              choose to see the best in the other.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Remember, we&apos;re all human</h3>
            <p className="[ blurb__body ]">
              All of us make mistakes. Don&apos;t demand a formal apology if a
              child or parent didn&apos;t act with malice. Be a human by helping
              others learn.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Teamwork isn&apos;t personal</h3>
            <p className="[ blurb__body ]">
              People can disagree with each other&apos;s ideas without thinking
              less of the other. When another parent or child does not agree
              with you or makes suggestions that change the work you&apos;ve
              done, that doesn&apos;t mean that they think that you are wrong or
              that the work you do is not valuable.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">
              Model the behavior you want to see
            </h3>
            <p className="[ blurb__body ]">
              Be a learner, a helper, and a teacher. Fill the knowledge, social,
              and love gaps in each others&apos; lives. Let your curiosity guide
              you to ask lots of questions and listen instead of trying to show
              that you have all of the answers.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Don&apos;t be overly-critical</h3>
            <p className="[ blurb__body ]">
              People understand when they&apos;ve made a mistake. It is
              unnecessary to hammer in the point just to show disapproval or
              because you believe you deserve justice. Choose to let the mistake
              go and handle the situation with kindness and empathy.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Build bridges to each other</h3>
            <p className="[ blurb__body ]">
              Every person has their own stories, experiences, and backgrounds.
              That is wonderful. Seek what we have in common and the unique
              perspective each community member can offer. There&apos;s enough
              in the world dividing us today.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Everyone makes mistakes</h3>
            <p className="[ blurb__body ]">
              Mistakes are part of life. Especially when we&apos;re trying
              something new and difficult. Expect that we will all make mistakes
              together and that we need to accept those mistakes with grace and
              love.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">It&apos;s okay to fail</h3>
            <p className="[ blurb__body ]">
              It&apos;s even good to fail! We learn from our failures and use
              them to grow into the best versions of ourselves. Success for
              infants, children, and adults is a series of thousands of failures
              that refine our abilities to handle a task, situation, or problem
              better each time.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">Pump the brakes, take a breath</h3>
            <p className="[ blurb__body ]">
              It&apos;s OK to slow down and take a breath. It&apos;s even okay
              to give yourself a couple days to mull things over before
              responding. This isn&apos;t a race. Learning new things takes time
              for both kids and for parents, so give yourself the time you need.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">
              Praise the good, ignore the bad
            </h3>
            <p className="[ blurb__body ]">
              Positivity is better at motivating people than punishment. When we
              approach every situation with positivity, we create a safe and
              eager learning-environment. Let go of the negative and celebrate
              the positive instead.
            </p>
          </li>

          <li className="[ blurb ]">
            <h3 className="[ blurb__title ]">It&apos;s okay to ask for help</h3>
            <p className="[ blurb__body ]">
              It&apos;s okay for both children and adults to ask for help.
              Asking for help doesn&apos;t make you weak, it shows that you are
              confident, eager to learn, and know when you&apos;re taking on too
              much at once.
            </p>
          </li>
        </ul>
      </div>
    </article>
  )
}

export default CommunityGuidelines
