import React from "react"
import { Helmet } from "react-helmet"

// Blocks
import {
  Beliefs,
  CommunityGuidelines,
  Hero,
  HealthGuidelines,
  Highlights,
  Steps,
} from "../components/home"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Hero />
      <Highlights />
      <Steps />
      <Beliefs />
      <CommunityGuidelines />
      <HealthGuidelines />
    </>
  )
}

export default IndexPage
